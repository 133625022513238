<!--  -->
<template>
<div class=''>
    <router-view></router-view>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import Cookies from "js-cookie";

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {

};
},
//监听属性 类似于data概念
computed: {
  crossPlatformStatus: {
    get() { return this.$store.state.module.crossPlatform },
    set(val) { this.$store.commit('module/updateCrossPlatform', val) }
  },
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  goECG(){
    console.log("看看2023年1月8日");
    console.log(Cookies.get('PID'));
    this.crossPlatformStatus = true
    let uid = Cookies.get('PID')
    this.$router.push({ name: 'HistorySingoTreat',
      query: {
        uid: uid,
      } })
    // let New_routeData = this.$router.resolve({
    //   name: 'HistorySingoJump',
    //   query: { uid: uid ,
    //     token: Cookies.get('token') ,}
    // })
    // window.open(New_routeData.href, '_blank')
  },
  goECGHolter(){
    console.log("看看2023年1月8日");
    console.log(Cookies.get('PID'));
    this.crossPlatformStatus = true
    let uid = Cookies.get('PID')

    this.$router.push({ name: 'HistorySingoHolterTreat',
      query: {
        uid: uid,
      } })
    // let New_routeData = this.$router.resolve({
    //   name: 'HistorySingoHolterJump1',
    //   query: { uid: uid ,
    //     token: Cookies.get('token') , }
    // })
    // console.log("kkkk",New_routeData.href)
    // window.open('https://www.xin-gou.com/test/#/across/HistorySingoHolterJump1?uid=2&token=0aa1815fdfa1a709ac19b9a1883be7a2')
  },
  goOtherFile(){
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      let uid = Cookies.get('PID')
      this.$router.push({ name: 'HistoryPhotoTreat',
        query: {
          uid: uid,
        } })
    },
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  // this.$router.push({ name: 'PsInfoJump11',
  //   query: {
  //     uid: "1",
  //   } })
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>